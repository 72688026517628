  $sprite-3d-banner-shadow-2x-x: 0px;
  $sprite-3d-banner-shadow-2x-y: 0px;
  $sprite-3d-banner-shadow-2x-offset-x: 0px;
  $sprite-3d-banner-shadow-2x-offset-y: 0px;
  $sprite-3d-banner-shadow-2x-width: 20px;
  $sprite-3d-banner-shadow-2x-height: 10px;
  $sprite-3d-banner-shadow-2x-total-width: 1270px;
  $sprite-3d-banner-shadow-2x-total-height: 4794px;
  $sprite-3d-banner-shadow-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-3d-banner-shadow-2x: 0px 0px 0px 0px 20px 10px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-accounts-icon-white-2x-x: 0px;
  $sprite-accounts-icon-white-2x-y: 998px;
  $sprite-accounts-icon-white-2x-offset-x: 0px;
  $sprite-accounts-icon-white-2x-offset-y: -998px;
  $sprite-accounts-icon-white-2x-width: 44px;
  $sprite-accounts-icon-white-2x-height: 42px;
  $sprite-accounts-icon-white-2x-total-width: 1270px;
  $sprite-accounts-icon-white-2x-total-height: 4794px;
  $sprite-accounts-icon-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-accounts-icon-white-2x: 0px 998px 0px -998px 44px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-accounts-icon-2x-x: 0px;
  $sprite-accounts-icon-2x-y: 778px;
  $sprite-accounts-icon-2x-offset-x: 0px;
  $sprite-accounts-icon-2x-offset-y: -778px;
  $sprite-accounts-icon-2x-width: 44px;
  $sprite-accounts-icon-2x-height: 42px;
  $sprite-accounts-icon-2x-total-width: 1270px;
  $sprite-accounts-icon-2x-total-height: 4794px;
  $sprite-accounts-icon-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-accounts-icon-2x: 0px 778px 0px -778px 44px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-add-to-favorites-active-2x-x: 0px;
  $sprite-add-to-favorites-active-2x-y: 406px;
  $sprite-add-to-favorites-active-2x-offset-x: 0px;
  $sprite-add-to-favorites-active-2x-offset-y: -406px;
  $sprite-add-to-favorites-active-2x-width: 33px;
  $sprite-add-to-favorites-active-2x-height: 30px;
  $sprite-add-to-favorites-active-2x-total-width: 1270px;
  $sprite-add-to-favorites-active-2x-total-height: 4794px;
  $sprite-add-to-favorites-active-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-add-to-favorites-active-2x: 0px 406px 0px -406px 33px 30px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-add-to-favorites-empty-2x-x: 0px;
  $sprite-add-to-favorites-empty-2x-y: 438px;
  $sprite-add-to-favorites-empty-2x-offset-x: 0px;
  $sprite-add-to-favorites-empty-2x-offset-y: -438px;
  $sprite-add-to-favorites-empty-2x-width: 33px;
  $sprite-add-to-favorites-empty-2x-height: 30px;
  $sprite-add-to-favorites-empty-2x-total-width: 1270px;
  $sprite-add-to-favorites-empty-2x-total-height: 4794px;
  $sprite-add-to-favorites-empty-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-add-to-favorites-empty-2x: 0px 438px 0px -438px 33px 30px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-breadcrumb-gt-black-2x-x: 0px;
  $sprite-breadcrumb-gt-black-2x-y: 36px;
  $sprite-breadcrumb-gt-black-2x-offset-x: 0px;
  $sprite-breadcrumb-gt-black-2x-offset-y: -36px;
  $sprite-breadcrumb-gt-black-2x-width: 14px;
  $sprite-breadcrumb-gt-black-2x-height: 16px;
  $sprite-breadcrumb-gt-black-2x-total-width: 1270px;
  $sprite-breadcrumb-gt-black-2x-total-height: 4794px;
  $sprite-breadcrumb-gt-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-breadcrumb-gt-black-2x: 0px 36px 0px -36px 14px 16px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-caret-left-2x-x: 0px;
  $sprite-caret-left-2x-y: 250px;
  $sprite-caret-left-2x-offset-x: 0px;
  $sprite-caret-left-2x-offset-y: -250px;
  $sprite-caret-left-2x-width: 18px;
  $sprite-caret-left-2x-height: 22px;
  $sprite-caret-left-2x-total-width: 1270px;
  $sprite-caret-left-2x-total-height: 4794px;
  $sprite-caret-left-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-caret-left-2x: 0px 250px 0px -250px 18px 22px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-caret-right-2x-x: 0px;
  $sprite-caret-right-2x-y: 274px;
  $sprite-caret-right-2x-offset-x: 0px;
  $sprite-caret-right-2x-offset-y: -274px;
  $sprite-caret-right-2x-width: 18px;
  $sprite-caret-right-2x-height: 22px;
  $sprite-caret-right-2x-total-width: 1270px;
  $sprite-caret-right-2x-total-height: 4794px;
  $sprite-caret-right-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-caret-right-2x: 0px 274px 0px -274px 18px 22px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-cart-black-2x-x: 0px;
  $sprite-cart-black-2x-y: 646px;
  $sprite-cart-black-2x-offset-x: 0px;
  $sprite-cart-black-2x-offset-y: -646px;
  $sprite-cart-black-2x-width: 42px;
  $sprite-cart-black-2x-height: 42px;
  $sprite-cart-black-2x-total-width: 1270px;
  $sprite-cart-black-2x-total-height: 4794px;
  $sprite-cart-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-cart-black-2x: 0px 646px 0px -646px 42px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-cart-white-2x-x: 0px;
  $sprite-cart-white-2x-y: 690px;
  $sprite-cart-white-2x-offset-x: 0px;
  $sprite-cart-white-2x-offset-y: -690px;
  $sprite-cart-white-2x-width: 42px;
  $sprite-cart-white-2x-height: 42px;
  $sprite-cart-white-2x-total-width: 1270px;
  $sprite-cart-white-2x-total-height: 4794px;
  $sprite-cart-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-cart-white-2x: 0px 690px 0px -690px 42px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-chestnut-character-2x-x: 0px;
  $sprite-chestnut-character-2x-y: 3128px;
  $sprite-chestnut-character-2x-offset-x: 0px;
  $sprite-chestnut-character-2x-offset-y: -3128px;
  $sprite-chestnut-character-2x-width: 152px;
  $sprite-chestnut-character-2x-height: 224px;
  $sprite-chestnut-character-2x-total-width: 1270px;
  $sprite-chestnut-character-2x-total-height: 4794px;
  $sprite-chestnut-character-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-chestnut-character-2x: 0px 3128px 0px -3128px 152px 224px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-close-icon-2x-x: 0px;
  $sprite-close-icon-2x-y: 376px;
  $sprite-close-icon-2x-offset-x: 0px;
  $sprite-close-icon-2x-offset-y: -376px;
  $sprite-close-icon-2x-width: 28px;
  $sprite-close-icon-2x-height: 28px;
  $sprite-close-icon-2x-total-width: 1270px;
  $sprite-close-icon-2x-total-height: 4794px;
  $sprite-close-icon-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-close-icon-2x: 0px 376px 0px -376px 28px 28px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-digicert-2x-x: 0px;
  $sprite-digicert-2x-y: 2404px;
  $sprite-digicert-2x-offset-x: 0px;
  $sprite-digicert-2x-offset-y: -2404px;
  $sprite-digicert-2x-width: 150px;
  $sprite-digicert-2x-height: 110px;
  $sprite-digicert-2x-total-width: 1270px;
  $sprite-digicert-2x-total-height: 4794px;
  $sprite-digicert-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-digicert-2x: 0px 2404px 0px -2404px 150px 110px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-dot-blue-2x-x: 0px;
  $sprite-dot-blue-2x-y: 116px;
  $sprite-dot-blue-2x-offset-x: 0px;
  $sprite-dot-blue-2x-offset-y: -116px;
  $sprite-dot-blue-2x-width: 20px;
  $sprite-dot-blue-2x-height: 20px;
  $sprite-dot-blue-2x-total-width: 1270px;
  $sprite-dot-blue-2x-total-height: 4794px;
  $sprite-dot-blue-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-dot-blue-2x: 0px 116px 0px -116px 20px 20px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-dot-gold-2x-x: 0px;
  $sprite-dot-gold-2x-y: 138px;
  $sprite-dot-gold-2x-offset-x: 0px;
  $sprite-dot-gold-2x-offset-y: -138px;
  $sprite-dot-gold-2x-width: 20px;
  $sprite-dot-gold-2x-height: 20px;
  $sprite-dot-gold-2x-total-width: 1270px;
  $sprite-dot-gold-2x-total-height: 4794px;
  $sprite-dot-gold-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-dot-gold-2x: 0px 138px 0px -138px 20px 20px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-dot-green-2x-x: 0px;
  $sprite-dot-green-2x-y: 160px;
  $sprite-dot-green-2x-offset-x: 0px;
  $sprite-dot-green-2x-offset-y: -160px;
  $sprite-dot-green-2x-width: 20px;
  $sprite-dot-green-2x-height: 20px;
  $sprite-dot-green-2x-total-width: 1270px;
  $sprite-dot-green-2x-total-height: 4794px;
  $sprite-dot-green-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-dot-green-2x: 0px 160px 0px -160px 20px 20px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-dot-orange-2x-x: 0px;
  $sprite-dot-orange-2x-y: 94px;
  $sprite-dot-orange-2x-offset-x: 0px;
  $sprite-dot-orange-2x-offset-y: -94px;
  $sprite-dot-orange-2x-width: 20px;
  $sprite-dot-orange-2x-height: 20px;
  $sprite-dot-orange-2x-total-width: 1270px;
  $sprite-dot-orange-2x-total-height: 4794px;
  $sprite-dot-orange-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-dot-orange-2x: 0px 94px 0px -94px 20px 20px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-dot-red-2x-x: 0px;
  $sprite-dot-red-2x-y: 204px;
  $sprite-dot-red-2x-offset-x: 0px;
  $sprite-dot-red-2x-offset-y: -204px;
  $sprite-dot-red-2x-width: 20px;
  $sprite-dot-red-2x-height: 20px;
  $sprite-dot-red-2x-total-width: 1270px;
  $sprite-dot-red-2x-total-height: 4794px;
  $sprite-dot-red-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-dot-red-2x: 0px 204px 0px -204px 20px 20px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-dot-rose-2x-x: 0px;
  $sprite-dot-rose-2x-y: 182px;
  $sprite-dot-rose-2x-offset-x: 0px;
  $sprite-dot-rose-2x-offset-y: -182px;
  $sprite-dot-rose-2x-width: 20px;
  $sprite-dot-rose-2x-height: 20px;
  $sprite-dot-rose-2x-total-width: 1270px;
  $sprite-dot-rose-2x-total-height: 4794px;
  $sprite-dot-rose-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-dot-rose-2x: 0px 182px 0px -182px 20px 20px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-dot-sign-out-2x-x: 0px;
  $sprite-dot-sign-out-2x-y: 72px;
  $sprite-dot-sign-out-2x-offset-x: 0px;
  $sprite-dot-sign-out-2x-offset-y: -72px;
  $sprite-dot-sign-out-2x-width: 20px;
  $sprite-dot-sign-out-2x-height: 20px;
  $sprite-dot-sign-out-2x-total-width: 1270px;
  $sprite-dot-sign-out-2x-total-height: 4794px;
  $sprite-dot-sign-out-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-dot-sign-out-2x: 0px 72px 0px -72px 20px 20px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-easy-reorder-icon-2x-x: 0px;
  $sprite-easy-reorder-icon-2x-y: 910px;
  $sprite-easy-reorder-icon-2x-offset-x: 0px;
  $sprite-easy-reorder-icon-2x-offset-y: -910px;
  $sprite-easy-reorder-icon-2x-width: 42px;
  $sprite-easy-reorder-icon-2x-height: 42px;
  $sprite-easy-reorder-icon-2x-total-width: 1270px;
  $sprite-easy-reorder-icon-2x-total-height: 4794px;
  $sprite-easy-reorder-icon-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-easy-reorder-icon-2x: 0px 910px 0px -910px 42px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-envelope-white-2x-x: 0px;
  $sprite-envelope-white-2x-y: 1646px;
  $sprite-envelope-white-2x-offset-x: 0px;
  $sprite-envelope-white-2x-offset-y: -1646px;
  $sprite-envelope-white-2x-width: 78px;
  $sprite-envelope-white-2x-height: 52px;
  $sprite-envelope-white-2x-total-width: 1270px;
  $sprite-envelope-white-2x-total-height: 4794px;
  $sprite-envelope-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-envelope-white-2x: 0px 1646px 0px -1646px 78px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-envelope-yellow-2x-x: 0px;
  $sprite-envelope-yellow-2x-y: 1808px;
  $sprite-envelope-yellow-2x-offset-x: 0px;
  $sprite-envelope-yellow-2x-offset-y: -1808px;
  $sprite-envelope-yellow-2x-width: 78px;
  $sprite-envelope-yellow-2x-height: 52px;
  $sprite-envelope-yellow-2x-total-width: 1270px;
  $sprite-envelope-yellow-2x-total-height: 4794px;
  $sprite-envelope-yellow-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-envelope-yellow-2x: 0px 1808px 0px -1808px 78px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-fb-logo-white-2x-x: 0px;
  $sprite-fb-logo-white-2x-y: 2256px;
  $sprite-fb-logo-white-2x-offset-x: 0px;
  $sprite-fb-logo-white-2x-offset-y: -2256px;
  $sprite-fb-logo-white-2x-width: 72px;
  $sprite-fb-logo-white-2x-height: 70px;
  $sprite-fb-logo-white-2x-total-width: 1270px;
  $sprite-fb-logo-white-2x-total-height: 4794px;
  $sprite-fb-logo-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-fb-logo-white-2x: 0px 2256px 0px -2256px 72px 70px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-fb-logo-yellow-2x-x: 0px;
  $sprite-fb-logo-yellow-2x-y: 2184px;
  $sprite-fb-logo-yellow-2x-offset-x: 0px;
  $sprite-fb-logo-yellow-2x-offset-y: -2184px;
  $sprite-fb-logo-yellow-2x-width: 72px;
  $sprite-fb-logo-yellow-2x-height: 70px;
  $sprite-fb-logo-yellow-2x-total-width: 1270px;
  $sprite-fb-logo-yellow-2x-total-height: 4794px;
  $sprite-fb-logo-yellow-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-fb-logo-yellow-2x: 0px 2184px 0px -2184px 72px 70px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-gluten-free-black-2x-x: 0px;
  $sprite-gluten-free-black-2x-y: 1592px;
  $sprite-gluten-free-black-2x-offset-x: 0px;
  $sprite-gluten-free-black-2x-offset-y: -1592px;
  $sprite-gluten-free-black-2x-width: 54px;
  $sprite-gluten-free-black-2x-height: 52px;
  $sprite-gluten-free-black-2x-total-width: 1270px;
  $sprite-gluten-free-black-2x-total-height: 4794px;
  $sprite-gluten-free-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-gluten-free-black-2x: 0px 1592px 0px -1592px 54px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-gluten-free-certified-black-2x-x: 0px;
  $sprite-gluten-free-certified-black-2x-y: 1538px;
  $sprite-gluten-free-certified-black-2x-offset-x: 0px;
  $sprite-gluten-free-certified-black-2x-offset-y: -1538px;
  $sprite-gluten-free-certified-black-2x-width: 76px;
  $sprite-gluten-free-certified-black-2x-height: 52px;
  $sprite-gluten-free-certified-black-2x-total-width: 1270px;
  $sprite-gluten-free-certified-black-2x-total-height: 4794px;
  $sprite-gluten-free-certified-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-gluten-free-certified-black-2x: 0px 1538px 0px -1538px 76px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-greater-than-blue-2x-x: 0px;
  $sprite-greater-than-blue-2x-y: 298px;
  $sprite-greater-than-blue-2x-offset-x: 0px;
  $sprite-greater-than-blue-2x-offset-y: -298px;
  $sprite-greater-than-blue-2x-width: 22px;
  $sprite-greater-than-blue-2x-height: 24px;
  $sprite-greater-than-blue-2x-total-width: 1270px;
  $sprite-greater-than-blue-2x-total-height: 4794px;
  $sprite-greater-than-blue-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-greater-than-blue-2x: 0px 298px 0px -298px 22px 24px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-greater-than-gray-2x-x: 0px;
  $sprite-greater-than-gray-2x-y: 324px;
  $sprite-greater-than-gray-2x-offset-x: 0px;
  $sprite-greater-than-gray-2x-offset-y: -324px;
  $sprite-greater-than-gray-2x-width: 22px;
  $sprite-greater-than-gray-2x-height: 24px;
  $sprite-greater-than-gray-2x-total-width: 1270px;
  $sprite-greater-than-gray-2x-total-height: 4794px;
  $sprite-greater-than-gray-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-greater-than-gray-2x: 0px 324px 0px -324px 22px 24px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-greater-than-tawny-2x-x: 0px;
  $sprite-greater-than-tawny-2x-y: 350px;
  $sprite-greater-than-tawny-2x-offset-x: 0px;
  $sprite-greater-than-tawny-2x-offset-y: -350px;
  $sprite-greater-than-tawny-2x-width: 22px;
  $sprite-greater-than-tawny-2x-height: 24px;
  $sprite-greater-than-tawny-2x-total-width: 1270px;
  $sprite-greater-than-tawny-2x-total-height: 4794px;
  $sprite-greater-than-tawny-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-greater-than-tawny-2x: 0px 350px 0px -350px 22px 24px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-guarantee-box-lg-2x-x: 0px;
  $sprite-guarantee-box-lg-2x-y: 3606px;
  $sprite-guarantee-box-lg-2x-offset-x: 0px;
  $sprite-guarantee-box-lg-2x-offset-y: -3606px;
  $sprite-guarantee-box-lg-2x-width: 1208px;
  $sprite-guarantee-box-lg-2x-height: 286px;
  $sprite-guarantee-box-lg-2x-total-width: 1270px;
  $sprite-guarantee-box-lg-2x-total-height: 4794px;
  $sprite-guarantee-box-lg-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-guarantee-box-lg-2x: 0px 3606px 0px -3606px 1208px 286px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-guarantee-box-mid-2x-x: 0px;
  $sprite-guarantee-box-mid-2x-y: 3894px;
  $sprite-guarantee-box-mid-2x-offset-x: 0px;
  $sprite-guarantee-box-mid-2x-offset-y: -3894px;
  $sprite-guarantee-box-mid-2x-width: 806px;
  $sprite-guarantee-box-mid-2x-height: 404px;
  $sprite-guarantee-box-mid-2x-total-width: 1270px;
  $sprite-guarantee-box-mid-2x-total-height: 4794px;
  $sprite-guarantee-box-mid-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-guarantee-box-mid-2x: 0px 3894px 0px -3894px 806px 404px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-guarantee-box-sm-2x-x: 0px;
  $sprite-guarantee-box-sm-2x-y: 4300px;
  $sprite-guarantee-box-sm-2x-offset-x: 0px;
  $sprite-guarantee-box-sm-2x-offset-y: -4300px;
  $sprite-guarantee-box-sm-2x-width: 554px;
  $sprite-guarantee-box-sm-2x-height: 494px;
  $sprite-guarantee-box-sm-2x-total-width: 1270px;
  $sprite-guarantee-box-sm-2x-total-height: 4794px;
  $sprite-guarantee-box-sm-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-guarantee-box-sm-2x: 0px 4300px 0px -4300px 554px 494px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-hamburger-close-x-2x-x: 0px;
  $sprite-hamburger-close-x-2x-y: 866px;
  $sprite-hamburger-close-x-2x-offset-x: 0px;
  $sprite-hamburger-close-x-2x-offset-y: -866px;
  $sprite-hamburger-close-x-2x-width: 42px;
  $sprite-hamburger-close-x-2x-height: 42px;
  $sprite-hamburger-close-x-2x-total-width: 1270px;
  $sprite-hamburger-close-x-2x-total-height: 4794px;
  $sprite-hamburger-close-x-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-hamburger-close-x-2x: 0px 866px 0px -866px 42px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-hamburger-icon-2x-x: 0px;
  $sprite-hamburger-icon-2x-y: 1224px;
  $sprite-hamburger-icon-2x-offset-x: 0px;
  $sprite-hamburger-icon-2x-offset-y: -1224px;
  $sprite-hamburger-icon-2x-width: 56px;
  $sprite-hamburger-icon-2x-height: 46px;
  $sprite-hamburger-icon-2x-total-width: 1270px;
  $sprite-hamburger-icon-2x-total-height: 4794px;
  $sprite-hamburger-icon-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-hamburger-icon-2x: 0px 1224px 0px -1224px 56px 46px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-hazelnut-character-2x-x: 0px;
  $sprite-hazelnut-character-2x-y: 2696px;
  $sprite-hazelnut-character-2x-offset-x: 0px;
  $sprite-hazelnut-character-2x-offset-y: -2696px;
  $sprite-hazelnut-character-2x-width: 146px;
  $sprite-hazelnut-character-2x-height: 208px;
  $sprite-hazelnut-character-2x-total-width: 1270px;
  $sprite-hazelnut-character-2x-total-height: 4794px;
  $sprite-hazelnut-character-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-hazelnut-character-2x: 0px 2696px 0px -2696px 146px 208px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-heart-white-2x-x: 0px;
  $sprite-heart-white-2x-y: 1324px;
  $sprite-heart-white-2x-offset-x: 0px;
  $sprite-heart-white-2x-offset-y: -1324px;
  $sprite-heart-white-2x-width: 57px;
  $sprite-heart-white-2x-height: 50px;
  $sprite-heart-white-2x-total-width: 1270px;
  $sprite-heart-white-2x-total-height: 4794px;
  $sprite-heart-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-heart-white-2x: 0px 1324px 0px -1324px 57px 50px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-heart-yellow-2x-x: 0px;
  $sprite-heart-yellow-2x-y: 1272px;
  $sprite-heart-yellow-2x-offset-x: 0px;
  $sprite-heart-yellow-2x-offset-y: -1272px;
  $sprite-heart-yellow-2x-width: 57px;
  $sprite-heart-yellow-2x-height: 50px;
  $sprite-heart-yellow-2x-total-width: 1270px;
  $sprite-heart-yellow-2x-total-height: 4794px;
  $sprite-heart-yellow-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-heart-yellow-2x: 0px 1272px 0px -1272px 57px 50px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-jumbo-double-quotes-blue-2x-x: 0px;
  $sprite-jumbo-double-quotes-blue-2x-y: 2328px;
  $sprite-jumbo-double-quotes-blue-2x-offset-x: 0px;
  $sprite-jumbo-double-quotes-blue-2x-offset-y: -2328px;
  $sprite-jumbo-double-quotes-blue-2x-width: 86px;
  $sprite-jumbo-double-quotes-blue-2x-height: 74px;
  $sprite-jumbo-double-quotes-blue-2x-total-width: 1270px;
  $sprite-jumbo-double-quotes-blue-2x-total-height: 4794px;
  $sprite-jumbo-double-quotes-blue-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-jumbo-double-quotes-blue-2x: 0px 2328px 0px -2328px 86px 74px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-kosher-black-2x-x: 0px;
  $sprite-kosher-black-2x-y: 1430px;
  $sprite-kosher-black-2x-offset-x: 0px;
  $sprite-kosher-black-2x-offset-y: -1430px;
  $sprite-kosher-black-2x-width: 52px;
  $sprite-kosher-black-2x-height: 52px;
  $sprite-kosher-black-2x-total-width: 1270px;
  $sprite-kosher-black-2x-total-height: 4794px;
  $sprite-kosher-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-kosher-black-2x: 0px 1430px 0px -1430px 52px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-kosher-dairy-2x-x: 0px;
  $sprite-kosher-dairy-2x-y: 1484px;
  $sprite-kosher-dairy-2x-offset-x: 0px;
  $sprite-kosher-dairy-2x-offset-y: -1484px;
  $sprite-kosher-dairy-2x-width: 52px;
  $sprite-kosher-dairy-2x-height: 52px;
  $sprite-kosher-dairy-2x-total-width: 1270px;
  $sprite-kosher-dairy-2x-total-height: 4794px;
  $sprite-kosher-dairy-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-kosher-dairy-2x: 0px 1484px 0px -1484px 52px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-magnifier-black-2x-x: 0px;
  $sprite-magnifier-black-2x-y: 1042px;
  $sprite-magnifier-black-2x-offset-x: 0px;
  $sprite-magnifier-black-2x-offset-y: -1042px;
  $sprite-magnifier-black-2x-width: 42px;
  $sprite-magnifier-black-2x-height: 42px;
  $sprite-magnifier-black-2x-total-width: 1270px;
  $sprite-magnifier-black-2x-total-height: 4794px;
  $sprite-magnifier-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-magnifier-black-2x: 0px 1042px 0px -1042px 42px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-magnifier-white-2x-x: 0px;
  $sprite-magnifier-white-2x-y: 954px;
  $sprite-magnifier-white-2x-offset-x: 0px;
  $sprite-magnifier-white-2x-offset-y: -954px;
  $sprite-magnifier-white-2x-width: 42px;
  $sprite-magnifier-white-2x-height: 42px;
  $sprite-magnifier-white-2x-total-width: 1270px;
  $sprite-magnifier-white-2x-total-height: 4794px;
  $sprite-magnifier-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-magnifier-white-2x: 0px 954px 0px -954px 42px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-main-nav-hr-2x-x: 0px;
  $sprite-main-nav-hr-2x-y: 54px;
  $sprite-main-nav-hr-2x-offset-x: 0px;
  $sprite-main-nav-hr-2x-offset-y: -54px;
  $sprite-main-nav-hr-2x-width: 1270px;
  $sprite-main-nav-hr-2x-height: 16px;
  $sprite-main-nav-hr-2x-total-width: 1270px;
  $sprite-main-nav-hr-2x-total-height: 4794px;
  $sprite-main-nav-hr-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-main-nav-hr-2x: 0px 54px 0px -54px 1270px 16px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-main-nav-separator-tall-2x-x: 0px;
  $sprite-main-nav-separator-tall-2x-y: 2906px;
  $sprite-main-nav-separator-tall-2x-offset-x: 0px;
  $sprite-main-nav-separator-tall-2x-offset-y: -2906px;
  $sprite-main-nav-separator-tall-2x-width: 8px;
  $sprite-main-nav-separator-tall-2x-height: 220px;
  $sprite-main-nav-separator-tall-2x-total-width: 1270px;
  $sprite-main-nav-separator-tall-2x-total-height: 4794px;
  $sprite-main-nav-separator-tall-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-main-nav-separator-tall-2x: 0px 2906px 0px -2906px 8px 220px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-main-nav-separator-2x-x: 0px;
  $sprite-main-nav-separator-2x-y: 1086px;
  $sprite-main-nav-separator-2x-offset-x: 0px;
  $sprite-main-nav-separator-2x-offset-y: -1086px;
  $sprite-main-nav-separator-2x-width: 4px;
  $sprite-main-nav-separator-2x-height: 44px;
  $sprite-main-nav-separator-2x-total-width: 1270px;
  $sprite-main-nav-separator-2x-total-height: 4794px;
  $sprite-main-nav-separator-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-main-nav-separator-2x: 0px 1086px 0px -1086px 4px 44px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-mini-cart-contents-footer-2x-x: 0px;
  $sprite-mini-cart-contents-footer-2x-y: 12px;
  $sprite-mini-cart-contents-footer-2x-offset-x: 0px;
  $sprite-mini-cart-contents-footer-2x-offset-y: -12px;
  $sprite-mini-cart-contents-footer-2x-width: 592px;
  $sprite-mini-cart-contents-footer-2x-height: 10px;
  $sprite-mini-cart-contents-footer-2x-total-width: 1270px;
  $sprite-mini-cart-contents-footer-2x-total-height: 4794px;
  $sprite-mini-cart-contents-footer-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-mini-cart-contents-footer-2x: 0px 12px 0px -12px 592px 10px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-mini-cart-contents-header-2x-x: 0px;
  $sprite-mini-cart-contents-header-2x-y: 24px;
  $sprite-mini-cart-contents-header-2x-offset-x: 0px;
  $sprite-mini-cart-contents-header-2x-offset-y: -24px;
  $sprite-mini-cart-contents-header-2x-width: 592px;
  $sprite-mini-cart-contents-header-2x-height: 10px;
  $sprite-mini-cart-contents-header-2x-total-width: 1270px;
  $sprite-mini-cart-contents-header-2x-total-height: 4794px;
  $sprite-mini-cart-contents-header-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-mini-cart-contents-header-2x: 0px 24px 0px -24px 592px 10px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-not-permitted-2x-x: 0px;
  $sprite-not-permitted-2x-y: 226px;
  $sprite-not-permitted-2x-offset-x: 0px;
  $sprite-not-permitted-2x-offset-y: -226px;
  $sprite-not-permitted-2x-width: 24px;
  $sprite-not-permitted-2x-height: 22px;
  $sprite-not-permitted-2x-total-width: 1270px;
  $sprite-not-permitted-2x-total-height: 4794px;
  $sprite-not-permitted-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-not-permitted-2x: 0px 226px 0px -226px 24px 22px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-nutty-family-2x-x: 0px;
  $sprite-nutty-family-2x-y: 3354px;
  $sprite-nutty-family-2x-offset-x: 0px;
  $sprite-nutty-family-2x-offset-y: -3354px;
  $sprite-nutty-family-2x-width: 366px;
  $sprite-nutty-family-2x-height: 250px;
  $sprite-nutty-family-2x-total-width: 1270px;
  $sprite-nutty-family-2x-total-height: 4794px;
  $sprite-nutty-family-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-nutty-family-2x: 0px 3354px 0px -3354px 366px 250px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-organic-black-2x-x: 0px;
  $sprite-organic-black-2x-y: 1700px;
  $sprite-organic-black-2x-offset-x: 0px;
  $sprite-organic-black-2x-offset-y: -1700px;
  $sprite-organic-black-2x-width: 52px;
  $sprite-organic-black-2x-height: 52px;
  $sprite-organic-black-2x-total-width: 1270px;
  $sprite-organic-black-2x-total-height: 4794px;
  $sprite-organic-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-organic-black-2x: 0px 1700px 0px -1700px 52px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-phone-icon-white-2x-x: 0px;
  $sprite-phone-icon-white-2x-y: 602px;
  $sprite-phone-icon-white-2x-offset-x: 0px;
  $sprite-phone-icon-white-2x-offset-y: -602px;
  $sprite-phone-icon-white-2x-width: 44px;
  $sprite-phone-icon-white-2x-height: 42px;
  $sprite-phone-icon-white-2x-total-width: 1270px;
  $sprite-phone-icon-white-2x-total-height: 4794px;
  $sprite-phone-icon-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-phone-icon-white-2x: 0px 602px 0px -602px 44px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-phone-icon-2x-x: 0px;
  $sprite-phone-icon-2x-y: 558px;
  $sprite-phone-icon-2x-offset-x: 0px;
  $sprite-phone-icon-2x-offset-y: -558px;
  $sprite-phone-icon-2x-width: 44px;
  $sprite-phone-icon-2x-height: 42px;
  $sprite-phone-icon-2x-total-width: 1270px;
  $sprite-phone-icon-2x-total-height: 4794px;
  $sprite-phone-icon-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-phone-icon-2x: 0px 558px 0px -558px 44px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-question-mark-black-2x-x: 0px;
  $sprite-question-mark-black-2x-y: 514px;
  $sprite-question-mark-black-2x-offset-x: 0px;
  $sprite-question-mark-black-2x-offset-y: -514px;
  $sprite-question-mark-black-2x-width: 42px;
  $sprite-question-mark-black-2x-height: 42px;
  $sprite-question-mark-black-2x-total-width: 1270px;
  $sprite-question-mark-black-2x-total-height: 4794px;
  $sprite-question-mark-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-question-mark-black-2x: 0px 514px 0px -514px 42px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-question-mark-white-2x-x: 0px;
  $sprite-question-mark-white-2x-y: 470px;
  $sprite-question-mark-white-2x-offset-x: 0px;
  $sprite-question-mark-white-2x-offset-y: -470px;
  $sprite-question-mark-white-2x-width: 42px;
  $sprite-question-mark-white-2x-height: 42px;
  $sprite-question-mark-white-2x-total-width: 1270px;
  $sprite-question-mark-white-2x-total-height: 4794px;
  $sprite-question-mark-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-question-mark-white-2x: 0px 470px 0px -470px 42px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-rss-logo-white-2x-x: 0px;
  $sprite-rss-logo-white-2x-y: 1376px;
  $sprite-rss-logo-white-2x-offset-x: 0px;
  $sprite-rss-logo-white-2x-offset-y: -1376px;
  $sprite-rss-logo-white-2x-width: 66px;
  $sprite-rss-logo-white-2x-height: 52px;
  $sprite-rss-logo-white-2x-total-width: 1270px;
  $sprite-rss-logo-white-2x-total-height: 4794px;
  $sprite-rss-logo-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-rss-logo-white-2x: 0px 1376px 0px -1376px 66px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-rss-logo-yellow-2x-x: 0px;
  $sprite-rss-logo-yellow-2x-y: 1754px;
  $sprite-rss-logo-yellow-2x-offset-x: 0px;
  $sprite-rss-logo-yellow-2x-offset-y: -1754px;
  $sprite-rss-logo-yellow-2x-width: 66px;
  $sprite-rss-logo-yellow-2x-height: 52px;
  $sprite-rss-logo-yellow-2x-total-width: 1270px;
  $sprite-rss-logo-yellow-2x-total-height: 4794px;
  $sprite-rss-logo-yellow-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-rss-logo-yellow-2x: 0px 1754px 0px -1754px 66px 52px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-searchy-the-peanut-2x-x: 0px;
  $sprite-searchy-the-peanut-2x-y: 2516px;
  $sprite-searchy-the-peanut-2x-offset-x: 0px;
  $sprite-searchy-the-peanut-2x-offset-y: -2516px;
  $sprite-searchy-the-peanut-2x-width: 166px;
  $sprite-searchy-the-peanut-2x-height: 178px;
  $sprite-searchy-the-peanut-2x-total-width: 1270px;
  $sprite-searchy-the-peanut-2x-total-height: 4794px;
  $sprite-searchy-the-peanut-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-searchy-the-peanut-2x: 0px 2516px 0px -2516px 166px 178px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-stars-blue-2x-x: 0px;
  $sprite-stars-blue-2x-y: 1986px;
  $sprite-stars-blue-2x-offset-x: 0px;
  $sprite-stars-blue-2x-offset-y: -1986px;
  $sprite-stars-blue-2x-width: 360px;
  $sprite-stars-blue-2x-height: 64px;
  $sprite-stars-blue-2x-total-width: 1270px;
  $sprite-stars-blue-2x-total-height: 4794px;
  $sprite-stars-blue-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-stars-blue-2x: 0px 1986px 0px -1986px 360px 64px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-stars-grey-2x-x: 0px;
  $sprite-stars-grey-2x-y: 2118px;
  $sprite-stars-grey-2x-offset-x: 0px;
  $sprite-stars-grey-2x-offset-y: -2118px;
  $sprite-stars-grey-2x-width: 360px;
  $sprite-stars-grey-2x-height: 64px;
  $sprite-stars-grey-2x-total-width: 1270px;
  $sprite-stars-grey-2x-total-height: 4794px;
  $sprite-stars-grey-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-stars-grey-2x: 0px 2118px 0px -2118px 360px 64px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-stars-yellow-2x-x: 0px;
  $sprite-stars-yellow-2x-y: 2052px;
  $sprite-stars-yellow-2x-offset-x: 0px;
  $sprite-stars-yellow-2x-offset-y: -2052px;
  $sprite-stars-yellow-2x-width: 360px;
  $sprite-stars-yellow-2x-height: 64px;
  $sprite-stars-yellow-2x-total-width: 1270px;
  $sprite-stars-yellow-2x-total-height: 4794px;
  $sprite-stars-yellow-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-stars-yellow-2x: 0px 2052px 0px -2052px 360px 64px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-tiny-peanut-black-2x-x: 0px;
  $sprite-tiny-peanut-black-2x-y: 822px;
  $sprite-tiny-peanut-black-2x-offset-x: 0px;
  $sprite-tiny-peanut-black-2x-offset-y: -822px;
  $sprite-tiny-peanut-black-2x-width: 31px;
  $sprite-tiny-peanut-black-2x-height: 42px;
  $sprite-tiny-peanut-black-2x-total-width: 1270px;
  $sprite-tiny-peanut-black-2x-total-height: 4794px;
  $sprite-tiny-peanut-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-tiny-peanut-black-2x: 0px 822px 0px -822px 31px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-tiny-peanut-white-2x-x: 0px;
  $sprite-tiny-peanut-white-2x-y: 734px;
  $sprite-tiny-peanut-white-2x-offset-x: 0px;
  $sprite-tiny-peanut-white-2x-offset-y: -734px;
  $sprite-tiny-peanut-white-2x-width: 31px;
  $sprite-tiny-peanut-white-2x-height: 42px;
  $sprite-tiny-peanut-white-2x-total-width: 1270px;
  $sprite-tiny-peanut-white-2x-total-height: 4794px;
  $sprite-tiny-peanut-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-tiny-peanut-white-2x: 0px 734px 0px -734px 31px 42px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-triple-bars-black-2x-x: 0px;
  $sprite-triple-bars-black-2x-y: 1178px;
  $sprite-triple-bars-black-2x-offset-x: 0px;
  $sprite-triple-bars-black-2x-offset-y: -1178px;
  $sprite-triple-bars-black-2x-width: 56px;
  $sprite-triple-bars-black-2x-height: 44px;
  $sprite-triple-bars-black-2x-total-width: 1270px;
  $sprite-triple-bars-black-2x-total-height: 4794px;
  $sprite-triple-bars-black-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-triple-bars-black-2x: 0px 1178px 0px -1178px 56px 44px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-triple-bars-white-2x-x: 0px;
  $sprite-triple-bars-white-2x-y: 1132px;
  $sprite-triple-bars-white-2x-offset-x: 0px;
  $sprite-triple-bars-white-2x-offset-y: -1132px;
  $sprite-triple-bars-white-2x-width: 56px;
  $sprite-triple-bars-white-2x-height: 44px;
  $sprite-triple-bars-white-2x-total-width: 1270px;
  $sprite-triple-bars-white-2x-total-height: 4794px;
  $sprite-triple-bars-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-triple-bars-white-2x: 0px 1132px 0px -1132px 56px 44px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-twitter-logo-white-2x-x: 0px;
  $sprite-twitter-logo-white-2x-y: 1924px;
  $sprite-twitter-logo-white-2x-offset-x: 0px;
  $sprite-twitter-logo-white-2x-offset-y: -1924px;
  $sprite-twitter-logo-white-2x-width: 76px;
  $sprite-twitter-logo-white-2x-height: 60px;
  $sprite-twitter-logo-white-2x-total-width: 1270px;
  $sprite-twitter-logo-white-2x-total-height: 4794px;
  $sprite-twitter-logo-white-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-twitter-logo-white-2x: 0px 1924px 0px -1924px 76px 60px 1270px 4794px '../img/sprites@2x-248167fc.png';
  $sprite-twitter-logo-yellow-2x-x: 0px;
  $sprite-twitter-logo-yellow-2x-y: 1862px;
  $sprite-twitter-logo-yellow-2x-offset-x: 0px;
  $sprite-twitter-logo-yellow-2x-offset-y: -1862px;
  $sprite-twitter-logo-yellow-2x-width: 76px;
  $sprite-twitter-logo-yellow-2x-height: 60px;
  $sprite-twitter-logo-yellow-2x-total-width: 1270px;
  $sprite-twitter-logo-yellow-2x-total-height: 4794px;
  $sprite-twitter-logo-yellow-2x-image: '../img/sprites@2x-248167fc.png';
  $sprite-twitter-logo-yellow-2x: 0px 1862px 0px -1862px 76px 60px 1270px 4794px '../img/sprites@2x-248167fc.png';

  @mixin sprite-width($sprite) {
    width: nth($sprite, 5);
  }

  @mixin sprite-height($sprite) {
    height: nth($sprite, 6);
  }

  @mixin sprite-position($sprite) {
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);
    background-position: $sprite-offset-x  $sprite-offset-y;
  }

  @mixin sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);
    background-image: url(#{$sprite-image});
  }

  @mixin sprite($sprite) {
    @include sprite-image($sprite);
    @include sprite-position($sprite);
    @include sprite-width($sprite);
    @include sprite-height($sprite);
  }
