// Floats
// -------------------------

.clearfix {
  @include clearfix();
}
.center-block {
  @include center-block();
}
.center-text {
  @include center-text();
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

.d-inline-block {
  display: inline-block;
}

//
// Responsive: Utility classes
// --------------------------------------------------

.full-width {
  width: 100%;
  max-width: 100%;
}

// Proportional aspect ratios
// Technique by https://css-tricks.com/aspect-ratio-boxes/#article-header-id-6
// Syntax by https://github.com/BrunoFenzl/sass-proportions
.prop-3x2 {
  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
  }
  &::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
  }
  &.vertical-align-middle {
    position: relative;
    > * {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 100%;
    }
  }
}

.prop-3x2::before {
  padding-top: 66.66667%;
}

// IE10 in Windows (Phone) 8
//
// Support for responsive views via media queries is kind of borked in IE10, for
// Surface/desktop in split view and for Windows Phone 8. This particular fix
// must be accompanied by a snippet of JavaScript to sniff the user agent and
// apply some conditional CSS to *only* the Surface/desktop Windows 8. Look at
// our Getting Started page for more information on this bug.
//
// For more information, see the following:
//
// Issue: https://github.com/twbs/bootstrap/issues/10497
// Docs: http://getbootstrap.com/getting-started/#browsers
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/

@-ms-viewport {
  width: device-width;
}

// Visibility utilities

.display-none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.ab-test-hidden {
  display: none;
}

@include responsive-invisibility(
  ".visible-xs, .visible-sm, .visible-md, .visible-lg"
);

@include respond-max($screen-xs-max) {
  @include responsive-visibility(".visible-xs");
}

@include respond-min-max($screen-sm-min, $screen-sm-max) {
  @include responsive-visibility(".visible-sm");
}

@include respond-min-max($screen-md-min, $screen-md-max) {
  @include responsive-visibility(".visible-md");
}

@include respond-min($screen-lg-min) {
  @include responsive-visibility(".visible-lg");
}

@include respond-max($screen-xs-max) {
  @include responsive-invisibility(".hidden-xs");
}

@include respond-min-max($screen-sm-min, $screen-sm-max) {
  @include responsive-invisibility(".hidden-sm");
}

@include respond-min-max($screen-md-min, $screen-md-max) {
  @include responsive-invisibility(".hidden-md");
}

@include respond-min($screen-lg-min) {
  @include responsive-invisibility(".hidden-lg");
}

// Print utilities
//
// Media queries are placed on the inside to be mixin-friendly.

@include responsive-invisibility(".visible-print");

@media print {
  @include responsive-visibility(".visible-print");
}

@media print {
  @include responsive-invisibility(".hidden-print");
}
