// buttons
$button-primary-background-color: $limeade;
$button-secondary-background-color: $tawny;
$button-light-background-color: $floral-white;
$button-extra-light-background-color: $white-ice-blue;

.button {
  border-radius: 9999px;
  border: 1px solid transparent;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 10px 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  line-height: normal;
  -webkit-appearance: none;
  font-family: $font-family-proxima;
  font-style: normal;
  font-weight: 600;

  &.default {
    background-color: buttonface;
    color: $text-color;
  }

  &.primary {
    background-color: $button-primary-background-color;
    color: #fff !important;
    &.disabled,
    &:disabled {
      background-color: $steel;
      cursor: default;
      opacity: 0.35;
    }
  }

  &.secondary {
    background-color: $button-secondary-background-color;
    color: #fff !important;
  }

  &.neutral {
    background-color: $light-shadow;
    color: $text-color;
  }

  &.light {
    background-color: $button-light-background-color;
    &:hover {
      background-color: $button-primary-background-color;
      color: white;
    }
  }

  &.xtra-light {
    background-color: $button-extra-light-background-color;
    color: $soft-black;
    &:hover {
      background-color: $button-primary-background-color;
      color: white;
    }
  }

  &.tertiary {
    background-color: rgb(111, 206, 228);
    color: #fff !important;
  }

  &.white {
    background-color: white;
    border: 1px solid #c4c4c4;
    color: black;
    &:hover {
      background-color: #f5f5f5;
      border: 1px solid transparent;
      box-shadow: 0 1px 1px 0 #a1a1a1;
    }
  }

  &.cancel {
    background-color: $white-smoke;
    color: $text-color;
  }

  &.huge {
    font-size: 18px;
    padding: 14px 35px;
  }

  &.compact {
    padding: 5px 15px;
  }

  &.extra-compact {
    padding: 3px 10px;
    font-size: 12px;
  }

  &.fill {
    display: block;
    width: 100%;
  }

  &.stackable {
    width: 100%;
    margin: 5px 0;
    @include respond-min($screen-sm-min) {
      margin: unset;
      width: auto;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.apple-pay {
    text-indent: -90000px;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    -apple-pay-button-style: black;
  }

  &.add-to-cart-button {
    display: flex;
    justify-content: center;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .spinner-icon {
      align-self: center;
    }
  }

  @include respond-max($screen-sm-max) {
    &.apple-pay-enabled {
      width: 45%;
    }
  }
  span {
    &.icon {
      &.inverse {
        svg {
          height: 100%;
        }
      }
    }
  }
}
