body {
  background-color: $body-background-color;
  position: relative;
}

img,
object,
textarea,
input {
  max-width: 100%;
}

hr {
  height: 3px;
  font-size: 0;
  border: 0;
  background: $hr-color;
  clear: both;
  margin: 25px 0;
}

input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

strong {
  font-weight: bold;
}

table {
  width: 100%;
}

p:not(.escape-legacy){
  margin-bottom: 15px;
}

b {
  font-weight: bold;
}

// LINKS

a {
  // font-weight: bold;
  &:link,
  &:visited {
    color: $link-color;
    text-decoration: none;
  }
  &:hover,
  &:focus,
  &:active {
    color: $link-hover-color;
    text-decoration: underline;
  }
  &[rel="next"]:after {
    content: " »";
  }
  &[rel="prev"]:before {
    content: "« ";
  }

  &.color-inherit {
    color: inherit;
  }

  &.tel,
  &.tel:link,
  &.tel:visited,
  &.tel:hover,
  &.tel:focus {
    color: inherit;
    text-decoration: inherit;
    white-space: nowrap;
  }

  &.underline,
  &.underlined {
    text-decoration: underline;
  }
}

// FORMS

input,
textarea  {
  border-color: #999;
  border-width: 1px;
  padding: 0.5em 0.25em;
  font-family: $font-family-sans-serif;
  font-size: 1em;
}

input[type="image"] {
  padding: 0;
  font-size: auto;
}

input.blur,
textarea.blur {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}
input:-moz-placeholder {
  color: #999;
}
input.sm {
  width: 50px;
}
input.centered {
  text-align: center;
}
form label {
  // font-weight: bold;
}
fieldset label {
  display: block;
  margin: 0.75em 1em 0.25em 0;
}

.page-content {
  padding-top: 25px;
  @include respond-max($screen-md-min) {
    padding-top: 10px;
  }
  padding-bottom: 25px;
}

.frame {
  padding: 20px;
  &.bottomless {
    padding-bottom: 0;
  }
  &.badge-padding {
    padding-right: 60px;
  }
}

.section {
  padding-bottom: 20px;
}

.divider {
  border-bottom: 3px solid $divider-color;
}

table.data :not(.escape-legacy){
  thead {
    background-color: $data-table-header-background-color;
  }
  th,
  td {
    text-align: right;
    padding: 6px;
    border-bottom: 1px solid $light-shadow;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  th {
    color: $soft-black;
  }
  td {
    font-weight: bold;
  }
  td:first-child {
    text-align: left;
  }
  tr.indent td {
    font-weight: normal;
    &:first-child {
      padding-left: 20px;
    }
  }
  &.left {
    th,
    td {
      text-align: left;
      &:last-child {
        text-align: right;
      }
    }
  }
  &.light {
    td {
      font-weight: normal;
    }
  }
}

.masonry {
  @include column-count(3);
  @include column-fill(auto);
  @include column-gap(30px);
  margin-left: 0;
  margin-right: 0;
  > div {
    display: block;
    width: auto;
    float: none;
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    padding-left: 0;
    padding-right: 0;
  }
}
@include respond-max($screen-xs-max) {
  .masonry {
    @include column-count(1);
  }
}

.arrow-link:after {
  content: " ";
  display: inline-block;
  margin: 0 0 -2px 9px;
  @include retina-sprite($sprite-greater-than-blue-1x);
}

.tiny-print {
  color: $dark-grey;
  font-size: 13px;
  margin: 0;
}

.text-right {
  text-align: right;
}
