.fade {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }
}

.page-fade {
  &-enter-active,
  &-leave-active,
  &-manual-transition {
    transition: opacity ease-in-out 0.15s;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

.transition-cursor-blocker {
  z-index: 8888;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
