// Utilities
// -------------------------

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Center-align a block level element
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Center-align the text content
@mixin center-text() {
  text-align: center;
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CSS image replacement
//
// Heads up! v3 launched with with only `.hide-text()`, but per our pattern for
// mixins being reused as classes with the same name, this doesn't hold up. As
// of v3.0.1 we have added `.text-hide()` and deprecated `.hide-text()`. Note
// that we cannot chain the mixins together in Less, so they are repeated.
//
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757

// Deprecated as of v3.0.1 (will be removed in v4)
@mixin hide-text() {
  font: #{0/0} a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
// New mixin to use as of v3.0.1
@mixin text-hide() {
  @include hide-text();
}

// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
  table#{$parent} {
    display: table;
  }
  tr#{$parent} {
    display: table-row !important;
  }
  th#{$parent},
  td#{$parent} {
    display: table-cell !important;
  }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}

// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken($border-color, 10%);
      $shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px lighten($border-color, 20%);
      @include box-shadow($shadow);
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), 0.6);
  &:focus {
    border-color: $color;
    outline: 0;
    // @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $color-rgba;
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

// [converter] $parent hack
@mixin input-size(
  $parent,
  $input-height,
  $padding-vertical,
  $padding-horizontal,
  $font-size,
  $line-height,
  $border-radius
) {
  #{$parent} {
    height: $input-height;
    padding: $padding-vertical $padding-horizontal;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
  }

  select#{$parent} {
    height: $input-height;
    line-height: $input-height;
  }

  textarea#{$parent},
  select[multiple]#{$parent} {
    height: auto;
  }
}

// Retina sprite fun
@mixin retina-sprite($sprite-1x) {
  @include sprite($sprite-1x);
  @include hidpi {
    background-image: url("" + str-replace(nth($sprite-1x, 9), "1x", "2x")); // assumes 1x and 2x sheets have same hash
    background-size: nth($sprite-1x, 7) nth($sprite-1x, 8);
  }
}

@mixin rounded {
  border-radius: $border-radius-large;
}

@mixin roundedBySize($size) {
  border-radius: $size !important;
}

// from http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin font-nutcase {
  font-family: "Nutcase";
  text-transform: uppercase;
  font-feature-settings: "kern" on, "liga" on, "calt" on;
  -moz-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -webkit-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -ms-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -o-font-feature-settings: "kern" on, "liga" on, "calt" on;
  -webkit-text-stroke: 0.3px; // suggested fix from Jeremy Mickel for jagginess on windows
}

// from http://jakearchibald.github.io/sass-ie/
$lock-media-queries-to: false !default;

@mixin respond-min($width) {
  @if $lock-media-queries-to {
    @if $lock-media-queries-to >= $width {
      @content;
    }
  } @else {
    @media screen and (min-width: $width) {
      @content;
    }
  }
}

@mixin respond-min-max($min-width, $max-width) {
  @if $lock-media-queries-to {
    @if $lock-media-queries-to >= $min-width {
      @if $lock-media-queries-to <= $max-width {
        @content;
      }
    }
  } @else {
    @media screen and (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  }
}

@mixin respond-max($width) {
  @if $lock-media-queries-to {
    @if $lock-media-queries-to <= $width {
      @content;
    }
  } @else {
    @media screen and (max-width: $width) {
      @content;
    }
  }
}

// from https://gist.github.com/sethbro/865076
@mixin speech-bubble($arrow_dir: bottom, $arrow_pos: 10%, $color: white, $border_width: 2px, $border_color: #fff) {
  border-style: solid;
  border-width: $border_width;
  border-color: $border_color;
  background-color: $color;
  position: relative;
  overflow: visible;

  $dir_x: right;
  $dir_y: top;
  $dir_not_x: left;
  $dir_not_y: bottom;
  @if $arrow_dir == top {
    $dir_x: bottom;
    $dir_y: left;
    $dir_not_x: top;
    $dir_not_y: right;
  } @else if $arrow_dir == right {
    $dir_x: left;
    $dir_y: top;
    $dir_not_x: right;
    $dir_not_y: bottom;
  } @else if $arrow_dir == bottom {
    $dir_x: top;
    $dir_y: left;
    $dir_not_x: bottom;
    $dir_not_y: right;
  }

  $default_arrow_width: 5px;
  &:before,
  &:after {
    content: "";
    width: 0;
    height: 0;
    border: solid transparent;
    position: absolute;
    #{$dir_x}: 100%;
    #{$dir_y}: $arrow_pos;
  }
  &:before {
    border-width: $default_arrow_width + $border_width;
    border-#{$dir_x}-color: $border_color;
    border-#{$dir_y}-color: $border_color;
    border-#{$dir_not_x}-color: transparent;
    border-#{$dir_not_y}-color: transparent;
    margin-#{$dir_y}: ($default_arrow_width + ($border_width * 2)) * -1;
  }
  &:after {
    border-width: $default_arrow_width;
    border-#{$dir_x}-color: $color;
    border-#{$dir_y}-color: $color;
    border-#{$dir_not_x}-color: transparent;
    border-#{$dir_not_y}-color: transparent;
    margin-#{$dir_y}: ($default_arrow_width + $border_width) * -1;
    margin-#{$dir_x}: -1px;
  }
}

/**
 * Replace `$search` with `$replace` in `$string`.
 * Case sensitive.
 *
 * @param {String} $string - haystack
 * @param {String} $search - needle
 * @param {String} $replace ('') - replacement
 *
 * @return {String}
 */
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  $slice: $string;
  @while $index {
    $slice: if($index == 1, "", str-slice($slice, 0, $index - 1)) +
      $replace +
      str-slice($slice, $index + max(str-length($replace), 1));
    $index: str-index($slice, $search);
  }
  @return $slice;
}

@mixin no-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
