@mixin container-fixed() {
  margin-right: auto;
  margin-left: auto;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  @include clearfix();
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
  @include clearfix();
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
  @include respond-min($screen-xs-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xs-column-push($columns) {
  @include respond-min($screen-xs-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xs-column-pull($columns) {
  @include respond-min($screen-xs-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

  @include respond-min($screen-sm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-offset($columns) {
  @include respond-min($screen-sm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @include respond-min($screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @include respond-min($screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

  @include respond-min($screen-md-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-offset($columns) {
  @include respond-min($screen-md-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @include respond-min($screen-md-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @include respond-min($screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

  @include respond-min($screen-lg-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-offset($columns) {
  @include respond-min($screen-lg-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @include respond-min($screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @include respond-min($screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}

@mixin make-column-flex-order($column, $class: null) {
  @if $class {
    .order-#{$class}-#{$column} {
      order: $column;
    }
  } @else {
    .order-#{$column} {
      order: $column;
    }
  }
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns() {
  $list: "";
  $i: 1;
  $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class) {
  $list: "";
  $i: 1;
  $list: ".col-#{$class}-#{$i}";
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }
  #{$list} {
    float: left;
  }
}

@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == pull) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == order) {
    @include make-column-flex-order($index, $class);
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}

// Create grid for specific class
@mixin make-grid($class) {
  @include float-grid-columns($class);
  @include loop-grid-columns($grid-columns, $class, width);
  @include loop-grid-columns($grid-columns, $class, pull);
  @include loop-grid-columns($grid-columns, $class, push);
  @include loop-grid-columns($grid-columns, $class, offset);
  @include loop-grid-columns($grid-columns, $class, flexbox);
  @include loop-grid-columns($grid-columns, $class, order);
}
.container {
  @include container-fixed();
  @include respond-min($screen-sm-min) {
    width: $container-sm;
  }
  @include respond-min($screen-md-min) {
    width: $container-md;
  }
  @include respond-min($screen-lg-min) {
    width: $container-lg;
  }
}

.row {
  @include make-row();
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- */
  display: -ms-flexbox; /* IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: row;

  &.wrapped {
    flex-wrap: wrap;
  }

  &.gutters {
    margin-right: -15px;
    margin-left: -15px;

    > .flex-col,
    > [class*="flex-xs-"],
    > [class*="flex-sm-"],
    > [class*="flex-md-"],
    > [class*="flex-lg-"] {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  &.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .flex-col,
    > [class*="flex-xs-"],
    > [class*="flex-sm-"],
    > [class*="flex-md-"],
    > [class*="flex-lg-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &.ruled {
    > .flex-col,
    > [class*="flex-xs-"],
    > [class*="flex-sm-"],
    > [class*="flex-md-"],
    > [class*="flex-lg-"] {
      border-bottom: 1px solid $alto;
    }
  }

  &.justify {
    &-content {
      &-end {
        justify-content: flex-end;
      }
      &-start {
        justify-content: flex-start;
      }
    }
    &-space {
      &-around {
        justify-content: space-around;
      }
      &-between {
        justify-content: space-between;
      }
      &-evenly {
        justify-content: space-evenly;
      }
    }
  }
}

.align {
  &-content {
    &-start {
      align-content: flex-start;
    }
    &-center {
      align-content: center;
    }
    &-end {
      align-content: flex-end;
    }
  }
  &-items {
    &-start {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
  }
  &-self {
    &-start {
      align-self: flex-start;
    }
    &-center {
      align-self: center;
    }
    &-end {
      align-self: flex-end;
    }
  }
}

@include make-grid-columns();
@for $i from 0 through $grid-columns {
  @include make-column-flex-order($i);
}
@include make-grid(xs);
@include respond-min($screen-sm-min) {
  @include make-grid(sm);
}
@include respond-min($screen-md-min) {
  @include make-grid(md);
}
@include respond-min($screen-lg-min) {
  @include make-grid(lg);
}
