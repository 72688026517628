span {
    &.icon {
      &.disabled {
        svg {
          path {
            fill: $gray
          }
        }
      }
      &.flipped {
        svg {
          transform: rotate(180deg);
        }
      }
    }
}
