.bulleted,
.nested,
.numbered,
.spaced {
  margin-bottom: 15px;
}

ul.bulleted {
  padding-left: 15px;
 li {
    list-style: disc outside;
  }
  ul {
    margin-left: 15px;
  }
}

ul.nested {
  ul {
    margin-left: 15px;
  }
}

ol.numbered {
  margin-left: 30px;
  li {
    list-style-position: outside;
  }
  ol {
    margin-left: 30px;
  }
}

.spaced {
  li {
    margin-bottom: 10px;
  }
  ol, ul {
    margin-top: 10px;
  }
}
